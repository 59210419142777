body{
  overflow: hidden;
}
$gutter: .5rem; //for primeflex grid system

// @import "../node_modules/primeng/resources/primeng.min.css";
// @import "../node_modules/primeflex/primeflex.scss";
// @import "../node_modules/primeicons/primeicons.css";
// @import "../node_modules/prismjs/themes/prism-coy.css";
// @import "../node_modules/@fullcalendar/core/main.min.css";
// @import "../node_modules/@fullcalendar/daygrid/main.min.css";
// @import "../node_modules/@fullcalendar/timegrid/main.min.css";
// @import "assets/demo/flags/flags.css";
// @import "assets/sass/overrides/layout_variables";

@import "../src/app/styles/variables";
@import "../src/app/styles/mixins";
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

.navigation {

    @include flex-between;
    padding: 24px;
    background: $color-dark;
  
    &__logo{
      width: 250px;
    }
  
    &__contact{
      @include flex-column;
    }
  
    &__contact-content{
      color: $color-light-gray;
      @include flex-center;
      font-family: $open-sans;
      margin-bottom: 5px;
  
    }
  
    &__contact-title{
      font-family: $open-sans;
      color: $color-light;
      font-weight: 700;
      font-size: 2rem;
      text-align: left;
    }
  
    &__contact-icon{
      margin-right: 5px;
      border: 1px solid $color-light-gray;
      border-radius: 50%;
      padding: 3px;
      font-size: 10px;
    }
  
  }
  .navigation-section {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .navigation-section .signout-btn button{
    padding: 0.5rem 1.5rem;
    margin-right: 16px;
      cursor: pointer;
      border-radius: 2px;
  }
  
  @media (max-height: 780px) {
    .navigation {
      &__logo {
        width: 200px;
      }
      
      &__contact-title {
        font-size: 1.5rem;
      }
    }
  }
.home {
    height: calc(100vh - 86.16px - 27px);
    background: $color-primary;
    @include flex-between;
    align-items: flex-start;
  
    &__portals-list {
      flex: 1;
      margin-top: 3rem;
    }
  
    &__portals-list-item {
      list-style-type: none;
      margin-bottom: 5px;
      background: $bg-color-list-item;
      padding: 7px 1rem;
      border-radius: 0 15px 15px 0;
      width: 400px;
      position: relative;
      cursor: pointer;
      transition: 0.4s ease;
  
      &:hover {
        background: #395074;
  
        .home__portals-item-subtitle {
          color: #ddd;
        }
      }
    }
  
    &__list-share-icon {
      position: absolute;
      width: 24px;
      right: 20px;
      top: 20px;
    }
  
    &__portals-item-title {
      font-family: $open-sans;
      color: $color-light;
      font-weight: 600;
    }
  
    &__portals-item-subtitle {
      font-family: $open-sans;
      color: $color-list-item-subtitle;
      font-weight: 600;
    }
  
    &__hero {
      flex: 1;
      text-align: center;
      font-family: $open-sans;
      margin-top: 3rem;
    }
  
    &__hero-welcome {
      color: $color-landing-title;
      font-size: 1.5rem;
      text-transform: uppercase;
    }
  
    &__hero-title {
      color: $color-highlight;
      font-size: 3.5rem;
  
      span {
        color: $color-light;
        position: relative;
        left: -14px;
      }
    }
  
    &__hero-subtitle {
      color: $color-landing-subtitle;
      font-size: 1.25rem;
      letter-spacing: 1px;
      margin-bottom: 4rem;
    }
  
    &__hero-image {
      width: 80%;
    }
  }
  
  @media (max-width: 1280px) {
    .home {
      &__hero-image {
        width: auto;
      }
      &__title {
        font-size: 1.25rem;
      }
    }
  }
  
  @media (max-height: 780px) {
    .home {
      &__hero-image {
        height: 250px;
        object-fit: contain;
      }
      
      &__title {
        font-size: 1.25rem;
      }
  
      &__portals-list {
        margin-top: 1.75rem;
      }
    }
  }
  .login-body .login-wrapper .login-panel .login-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0;
    font-family: $open-sans;
    color: $color-light;
    background: transparent;
    width: 90%;
    padding: 5rem 1rem;
    margin-top: 14%;
  
    h2{
      margin-bottom: 10px;
    }
  
  }
  .login-body .login-wrapper .login-panel .login-form > button {
    width: 90%;
    max-width: 400px;
    margin: 5px;
  }
  .signIn-office {
    margin-top: 15px;
    text-align: center;
    font-size: 20px;
    font-weight: 1.5rem;
    color: #ffffff;
    background: #ee1242;
    outline: none;
    border: none;
    padding: 15px;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #ee1242;
  
    &:hover {
      box-shadow: 3px 3px #000;
      padding-left: 27px;
    }
  }
  .signIn-office img {
    width: 20px;
    vertical-align:text-top;
    margin-right: 10px;
  }
  .version-text {
    margin-top: 5px;
  }
  .login-panel {
    width: 90%;
    position: relative;
    margin: auto;
  }
  // .login-panel img {
  //   width: 100%;
  // }
  .footer-information{
    background: $color-dark;
    text-align: center;
    color: $color-light;
    padding: 4px 0;
    font-size: 14px;
    font-family: $open-sans;
  }
  