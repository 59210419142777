@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

$color-dark : #000;
$color-light : #fff;
$color-light-gray : #c4c3c3;
$open-sans : 'Open Sans', sans-serif;
$color-primary : #202E44;
$color-highlight : #C00000;
$bg-color-list-item:#16202F;
$color-list-item-subtitle : #7B7B7C;
$color-list-item-title : #868B91;
$color-redirect-icon : #66676A;
$color-landing-title:#D0CECE;
$color-landing-subtitle : #AEAFB3;
